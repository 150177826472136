<template>
  <div>
    <!-- Row Start -->
    <div class="row">
      <div class="col-8 offset-2">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Zone</h3>
            </div>
          </div>

          <div class="card-body body-fluid">
            <div class="row">
              <div class="col-md-4">
                <b-form-group label="Zone ID">
                  <b-form-input v-model="form.id" disabled></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Zone Name">
                  <b-form-input v-model="form.name"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Sync list">
                  <b-form-select
                    :options="synclist"
                    value-field="val"
                    v-model="form.sync_list"
                    text-field="name"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <b-form-group label="Currency">
                  <b-form-select
                    :options="currencies"
                    value-field="id"
                    v-model="form.currency_id"
                    text-field="code"
                  >
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-8 pt-10">
                <b-button class="float-right" variant="primary" @click="updateZone"
                  >Update</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Locations</h3>
            </div>
          </div>

          <div class="card-body body-fluid">
            <div class="row">
              <div class="col-md-3">
                <b-form-group label="Origin">
                  <b-form-select
                    :options="countries"
                    value-field="name"
                    v-model="locationform.country"
                    @change="changeCountry"
                    text-field="name"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >Select Country</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label="State">
                  <b-form-select
                    :options="states"
                    value-field="name"
                    v-model="locationform.state"
                    text-field="name"
                  >
                    <template #first>
                      <b-form-select-option :value="null" disabled
                        >Select States</b-form-select-option
                      >
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label="City">
                  <b-form-input v-model="locationform.city"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-form-group label="Code">
                  <b-form-input v-model="locationform.code"></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-3">
                <b-button variant="light" @click="addlocation"
                  >Add Location</b-button
                >
              </div>
              <div class="col-md-12">
                <div class="table-responsive">
                  <b-table
                    responsive
                    class="text-nowrap"
                    small
                    striped
                    hover
                    :items="form.locations"
                    :fields="fields"
                  >
                    <template #cell(actions)="row">
                      <!-- <b-button
                        size="sm"
                        @click="ShowEditModal(row.item.id)"
                        variant="primary"
                      >
                        <i class="flaticon2-edit icon-sm"></i
                      ></b-button> -->
                      <b-button
                        @click="deletezonelocation(row.item.id)"
                        class="ml-2"
                        size="sm"
                        variant="danger"
                      >
                        Delete</b-button
                      >
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>
<script>
// import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  data() {
    return {
      currencies: [],
      errors: [],
      isLoading: false,
      id: this.$route.params.id,
      synclist: [
        { val: "all", name: "All" },
        { val: "user", name: "User" },
        { val: "origin", name: "Origin" },
        { val: "destination", name: "Destination" },
        { val: "user-origin", name: "User - Origin" },
        { val: "user-destination", name: "User - Destination" },
        { val: "origin-destination", name: "Origin - Destination" },
        { val: null, name: "None" },
      ],
      // countries: [
      //   { name: "Indonesia" },
      //   { name: "Singapore" },
      //   { name: "Malaysia" },
      //   { name: "Australia" },
      // ],
      // states: [
      //   { name: "Jakarta" },
      //   { name: "Singapore" },
      //   { name: "Johor" },
      //   { name: "Perth" },
      // ],
      states: [],
      allstates: [],
      countries: [],
      form: {
        name: null,
        sync_list: null,
        currency_id: null,
        locations: null,
        id: this.$route.params.id,
      },
      locationform: {
        shipping_zone_id: this.$route.params.id,
        country: null,
        state: null,
        city: null,
        code: null,
        id: null,
      },
      fields: [
        { key: "country", label: "Country" },
        { key: "state", label: "State" },
        {
          key: "city",
          label: "City",
          formatter: (value) => {
            if (value == null) {
              return "All";
            } else {
              return value;
            }
          },
        },
        {
          key: "code",
          label: "Postal/Zip Code",
          formatter: (value) => {
            if (value == null) {
              return "All";
            } else {
              return value;
            }
          },
        },
        { key: "actions", label: "Actions" },
      ],
    };
  },
  created() {
    this.getZone();
    this.getAllCC();
    this.getAllCountries();
    this.getAllStates();
  },
  components: {
    Loading,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Shipping" },
      { title: "Currency Conversion" },
    ]);
  },
  methods: {
    checkErrors() {
      if (this.locationform.country && this.locationform.state) {
        return true;
      }

      this.errors = [];

      if (!this.locationform.country) {
        this.errors.push("Country is required.");
      }
      if (!this.locationform.state) {
        this.errors.push("State is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    resetModal() {
      this.locationform.country = null;
      this.locationform.state = null;
      this.locationform.city = null;
      this.locationform.code = null;
    },
    addlocation(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.checkErrors() == true) {
        ApiService.post("addshippingzonelocation", this.locationform)
          .then(() => {
            this.getZone();
            this.$nextTick(() => {
              this.resetModal();
              //   this.$bvModal.hide("add-zone-modal");
              this.makeToastVariant(
                "success",
                "Location added successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    makeToastVariant(variant = null, text, title) {
      this.$bvToast.toast(text, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    checkErrorsUpdateZone() {
      if (this.form.name) {
        return true;
      }

      this.errors = [];
      if (!this.form.name) {
        this.errors.push("Zone Name is required.");
      }
      for (let field of this.errors) {
        this.makeToastVariant("danger", field, "Error");
      }
    },
    updateZone() {
      // Prevent modal from closing
      if (this.checkErrorsUpdateZone() == true) {
        this.isLoading = true;
        ApiService.update("shippingzone", this.form.id ,this.form)
          .then(() => {
            this.isLoading = false;
            this.getZone();
            this.$nextTick(() => {
              this.makeToastVariant(
                "info",
                "Zone updated successfully",
                "Success"
              );
            });
          })
          .catch(({ response }) => {
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant(
                "danger",
                response.data.error[field][0],
                "Error"
              );
            }
          });
      }
    },
    // updatecc(bvModalEvt) {
    //   bvModalEvt.preventDefault();
    //   if (this.checkErrors() == true) {
    //     ApiService.update("currencyconversion", this.form.id, this.form)
    //       .then(() => {
    //         this.getAllCC();
    //         this.$nextTick(() => {
    //           this.$bvModal.hide("edit-cc-modal");
    //           this.makeToastVariant(
    //             "info",
    //             "Currency Conversion updated successfully",
    //             "Success"
    //           );
    //         });
    //       })
    //       .catch(({ response }) => {
    //         for (let field of Object.keys(response.data.error)) {
    //           this.makeToastVariant(
    //             "danger",
    //             response.data.error[field][0],
    //             "Error"
    //           );
    //         }
    //       });
    //   }
    // },
    getAllCC() {
      ApiService.get("currencyconversion")
        .then(({ data }) => {
          
          this.currencies = data;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getZone() {
      ApiService.get("shippingzone", this.id)
        .then(({ data }) => {
          this.form = data.shippingzone;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    getAllStates() {
      ApiService.get("getstates")
        .then(({ data }) => {
          
          this.allstates = data;
          // this.states = data;
          // if (
          //   this.countries.filter((x) => x.name == this.form.receiver_country)
          //     .length > 0
          // ) {
          //   if (
          //     this.allstates.filter(
          //       (x) =>
          //         x.country_id ==
          //         this.countries.filter(
          //           (x) => x.name == this.form.receiver_country
          //         )[0].id
          //     ).length > 0
          //   ) {
          //     this.states = this.allstates.filter(
          //       (x) =>
          //         x.country_id ==
          //         this.countries.filter(
          //           (x) => x.name == this.form.receiver_country
          //         )[0].id
          //     );
          //   }
          // }
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    changeCountry() {
      if (
        this.countries.filter((x) => x.name == this.locationform.country)
          .length > 0
      ) {
        if (
          this.allstates.filter(
            (x) =>
              x.country_id ==
              this.countries.filter(
                (x) => x.name == this.locationform.country
              )[0].id
          ).length > 0
        ) {
          this.states = this.allstates.filter(
            (x) =>
              x.country_id ==
              this.countries.filter(
                (x) => x.name == this.locationform.country
              )[0].id
          );
          this.locationform.state = null;
        }
      }
      //If Country has only one State auto select that state PW-68
      if (this.states.length == 1) {
        this.locationform.state = this.states[0].name;
      }
    },
    getAllCountries() {
      ApiService.get("getcountries")
        .then(({ data }) => {
          this.countries = data;
          // this.form.receiver_country = this.countries.length
          //   ? this.countries[0].name
          //   : null;
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
    deletezonelocation(id) {
      ApiService.delete("deleteshippingzonelocation", id)
        .then(() => {
          this.getZone();
          this.makeToastVariant(
            "warning",
            "Location deleted successfully",
            "Success"
          );
        })
        .catch(() => {
          // context.commit(SET_ERROR, response.data.errors);
          // context.commit(SET_ERROR, response);
        });
    },
  },
};
</script>
